// gatsby-browser.js
import wrapWithProvider from "./wrap-with-provider";
import $ from 'jquery';

//LaTex
require(`katex/dist/katex.min.css`)

// Prismjs
require("prismjs/themes/prism-solarizedlight.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css");
require("prismjs/plugins/command-line/prism-command-line.css")

// Feel free to change the key to anything you want.
const LOCAL_STORAGE_KEY = 'documentPersistKeys';

const shouldReplaceUrls = process.env.GATSBY_REPLACE_URLS || true;

const getFormElements = () => $('form');

const parseBool = str => {
  if(str === null)
    return false;

  if (typeof str === 'boolean') {
    return (str === true);
  }

  if(typeof str === 'string') {
    if(str === "")
      return false;

    str = str.replace(/^\s+|\s+$/g, '');
    if(str.toLowerCase() === 'true' || str.toLowerCase() === 'yes')
      return true;

    str = str.replace(/,/g, '.');
    str = str.replace(/^\s*-\s*/g, '-');
  }

  if(!isNaN(str))
    return (parseFloat(str) !== 0);

  return false;
}

const addToStore = (store, k, v) => {
  store[k] = v;
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(store));
}

const deleteFromStore = (store = {}, k) => {
  let { [k]: _, ...result } = store;
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(result));
}

const replaceNameInstances = (id, v) => {
  $('div.document-page-content p, div.document-page-content pre').each(function () {
    let html = $(this).html();
    $(this).html(html.replace(new RegExp("\\b"+id+"\\b", 'g'), v));
  })
}

const keepFormValues = (id, v) => {
  $('.eosio-helper-box').find('input[name='+id+']').each(function () {
    $(this).val(v);
    $(this).siblings('span').text(v);
  });
}

const attachButtonListener = (k, v) => {
  $(document).on("click", "#del-"+k, function (e) {
    e.preventDefault();
    // We need to directly getItem so we can get the most recent state of localStorage
    // passing a 'store' parameter keeps a reference to the localStorage on page ready,
    // therefore getting an outdated version of the localStorage.
    deleteFromStore(JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)), k);
    keepFormValues(k, '');

    if (v && v.length > 0)
      replaceNameInstances(v, k);
  });
}

const isExternalUrl = (url) => {
  const naturalExternal = /^(http|https|ftp)/g;
  const DEVPORTAL_DOMAIN = 'http://developers.eos.io';
  const DEVPORTAL_DOMAIN_HTTPS = 'https://developers.eos.io';
  const DEVPORTAL_STAG = 'http://docsgen.a.qa.dev.b1ops.net.s3-website-us-west-2.amazonaws.com';
  const LOCALHOST = 'http://localhost:8000';
  const SITEURL = process.env.GATSBY_SITE_URL;

  if (naturalExternal.test(url)) {
    if (url.includes(DEVPORTAL_DOMAIN) || url.includes(DEVPORTAL_DOMAIN_HTTPS) || url.includes(DEVPORTAL_STAG) ||
        url.includes(LOCALHOST) || url.includes(SITEURL)) {
          return false;
        }
    else return true;
  } else {
    return false;
  }
}

const documentOnLoad = (store, formElements) => {
  const regex = /http:\/\/developers\.eos\.io|https:\/\/developers\.eos\.io|http:\/\/localhost:8000|https:\/\/localhost:8000/g;

  if (Object.keys(store).length === 0)
    window.localStorage.setItem(LOCAL_STORAGE_KEY, '{}');
  else {
    Object.keys(store).forEach(function (k) {
      replaceNameInstances(k, store[k]);
    })
  }

  $('div.document-page-content p, div.document-page-content pre').find('a').html(function (idx, oldHtml) {
    let href = this.href;
    if (isExternalUrl(href) && !this.className.includes('anchor')) {
      return oldHtml+'<i class="fa fa-external-link internal-icon" aria-hidden="true"></i>';
    } else return oldHtml;
  });

  $('div.document-page-content ul li').find('a').html(function (idx, oldHtml) {
    let href = this.href;
    if (isExternalUrl(href)) {
      return oldHtml+'<i class="fa fa-external-link internal-icon" aria-hidden="true"></i>';
    } else return oldHtml;
  })

  if (formElements.length > 0) {
    formElements.each(function () {
      let $form = $(this);
      let id = $form.attr('id');
      let val = store[id] || '';
      keepFormValues(id, val);
      attachButtonListener(id, val);
    })
  }

  if ($('#remove-all-cookies').length > 0) {
    $(document).on("click", "#clear-local-keys", function (e) {
      e.preventDefault();
      if (Object.keys(store).length > 0) {
        Object.keys(store).forEach(function (k) {
          keepFormValues(k, '');
        });
        window.localStorage.setItem(LOCAL_STORAGE_KEY, '{}');
      }
    });
  }

  // TODO: The following hacks should be replaced with longer-term solutions
  $('a[href*="developers.eos.io"]').attr('target', '_self')
  if (parseBool(shouldReplaceUrls)) {
    $('a').each(function () {
      const defaultSiteUrl = process.env.GATSBY_SITE_URL || "http://localhost:8080";
      this.href = this.href.replace(regex, defaultSiteUrl);
    })
  }
}

export const wrapRootElement = wrapWithProvider({window, document});
export const onRouteUpdate = ({ location }) => {
  $(document).ready(function (){
    const formElements = getFormElements();
    const regexp = /v[0-9]+\.[0-9]+/;
    let canonicalVersion = "latest";
    let storeObject = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) || {};

    if (location.pathname.match(regexp)) {
      canonicalVersion = location.pathname.match(regexp)[0];
    }

    document.addEventListener('gesturestart', function(e) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        document.body.style.zoom = 0.99;
    });
    
    document.addEventListener('gesturechange', function(e) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        document.body.style.zoom = 0.99;
    });
    
    document.addEventListener('gestureend', function(e) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        document.body.style.zoom = 0.99;
    });

    /**
     * Fix anchor links in latest tag permalink
     */

    $('a.anchor').each(function () {
      this.href = this.href.replace('/latest/',"/"+canonicalVersion+"/");
    })

    /**
     * Index page produces malformed ToC links in '//#anchor' format, needs to be fixed
     * via jQuery
     */
    if (
      $('.TableOfContents ul li a').length > 0 &&
      (
        window.location.origin+"/" === window.location.href ||
        window.location.origin+"/"+window.location.hash === window.location.href
      )
    ) {
      $('.TableOfContents ul li a').each(function () {
        let $link = $(this);
        let originalHref = $link.attr('href');
        let newId = originalHref.split('/')[2];
        let newHref = window.location.origin+"/"+newId;
        $link.attr('href', newHref);
      })
    }

    if (
      $('.TableOfContents ul li a').length > 0
    ) {
      $('.TableOfContents ul li a').addClass('menu-caret');

      $('#ToCHeader').click(function () {
        $('.TableOfContents ul li a').removeClass('active');
      });

      $('.TableOfContents ul li a').click(function () {
        let $link = $(this);
        $('.TableOfContents ul li a').removeClass('active');
        $link.addClass('active');
      })

      $('.TableOfContents ul li a').each(function () {
        this.href = this.href.replace('/latest/',"/"+canonicalVersion+"/");
      })
    }

    documentOnLoad(storeObject, formElements);

    if (formElements.length > 0) {
      formElements.each(function () {
        let $form = $(this);
        let id = $form.attr('id');
        let $i = $form.children('input.helper-cookie');

        keepFormValues(id, storeObject[id]);

        $form.off('submit');
        $form.on('submit', function (e) {
          e.preventDefault();
          let value = $i.val();
          // We need to directly getItem so we can get the most recent state of localStorage
          // 'storeObject' keeps a reference to the localStorage on page ready.
          if (id !== 'DocSearch') {
            addToStore(JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)), id, value);
            keepFormValues(id, value);
            replaceNameInstances(id, value);
          }
        });
      })
    }

  })
}
