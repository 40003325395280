import React from "react"
import { Provider } from "react-redux"

import createStore from "./src/store"

export default ({window, document}) => {

  const store = createStore({window, document});
  return ({ element }) => <Provider store={store}>{element}</Provider>
}
