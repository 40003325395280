import { createStore, compose } from 'redux';
import rootReducer from 'src/reducers';

export default ({window}) => {

  const initialState = {};
  const enhancers = [];

  if (process.env.NODE_ENV === 'development' && typeof window !== `undefined`) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    ...enhancers
  )

  return createStore(
    rootReducer,
    initialState,
    composedEnhancers
  );
};
