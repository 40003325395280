import actionTypes from 'src/consts/action-types';

const initialState = {
  isMenuOpen: false,
  menuScrollY: 0,
  activeIds: {
    currentTree: {},
    currentParent: {},
    currentDepth: 0,
    currentChildLink: ""
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.NAV_TOGGLE_HAMBURGER_MENU:
      return Object.assign({}, state, {
        isMenuOpen: typeof action.forceValue === "undefined" ? !state.isMenuOpen : action.forceValue,
      });
    case actionTypes.SET_MENU_SCROLL_Y:
      return Object.assign({}, state, {
        menuScrollY: action.offset || 0,
      });
    case actionTypes.STORE_NAV_DEPTH:
      let { depth = 0, tree = [] } = action.children;
      if (depth < tree.length) {
        tree.splice(depth+1);
      }
      let updatedState = {
        ...state.activeIds,
        navPath: tree
      }
      return Object.assign({}, state, {
        activeIds: updatedState
      });
    default:
      return state;
  }
}
